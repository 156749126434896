#recent-admin-div {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.gatekeeper-btn {
  padding: 3px 10px;
  margin: -1px 4px;
  border: none;
  font-family: monospace;
  font-weight: bold;
  font-size: 1.3em;
  background-color: black;
  color: white;
  border-radius: 5px;
  transition: 0.2s linear;
}
.gatekeeper-btn:hover {
  background-color: purple;
  cursor: pointer;
}
.action-btns-div {
  margin-top: -10px;
}
.accept-curriculums-div {
  margin-bottom: 10px;
  display: inline-block;
  width: 100%;
}
.confirm-emoji {
  background: transparent;
  border: none;
  padding: 10px 10px;
  margin: 5px;
  outline: none;
  border-radius: 20px;
  opacity: 0.8;
  cursor: pointer;
  transition: 0.1s linear;
}
#confirm-tick {
  background-color: RGBA(152, 251, 152, 0.4);
}
#confirm-tick:hover {
  background-color: RGBA(152, 251, 152, 1);
}
#confirm-cross {
  background-color: RGBA(255, 192, 203, 0.4);
}
#confirm-cross:hover {
  background-color: RGBA(255, 192, 203, 1);
}
