/* NAVBAR POSITIONING */
#nav-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  top: 0px;
  left: 0;
  background-color: white;
  color: rgb(39, 0, 110);
  padding: 10px 25px;
}
.nav-item {
  margin: 0 5px;
}
a#nav-logo {
  font-weight: bold;
  font-size: 1.2em;
}
.nav-right {
  display: flex;
  flex-direction: row;
  width: 30%;
  justify-content: flex-end;
  align-items: center;
}
.sign-btn {
  border: none;
  background-color: rgba(255, 255, 255, 0);
}
