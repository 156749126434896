.data-output {
  min-height: 200px;
}
/* TOGGLE FILTER */
.filter-title {
  color: black;
  font-size: 1.35em;
  margin-bottom: 7px;
}
.filter-container {
  max-width: 800px;
  font-size: 0.89em;
  align-items: center;
  color: #4d4d4d;
  padding: 10px 5px;
  margin: 12px auto 28px auto;
}
.filter-container::before {
  content: "";
  height: 40px;
  margin-top: -74px;
  display: block;
  position: relative;
  top: 74px;
  z-index: -1;
  background-color: #eeefff;
}
div#max-msg {
  margin: 20px auto 0px;
  background-color: mediumslateblue;
  color: white;
  font-size: 1.4em;
  font-family: HK Grotesk;
  font-weight: bold;
  width: fit-content;
  padding: 2px 5px;
  border-radius: 5px;
}
.selected-div {
  margin-top: 20px;
}
.selected-title {
  color: black;
  font-family: HK Grotesk;
  font-size: 1.2em;
  font-weight: bold;
}
.check-div {
  display: inline-block;
}
input,
label {
  cursor: pointer;
}
input[type="checkbox"] {
  appearance: none;
}
.check-label {
  font-family: HK Grotesk;
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.9);
  border: 2px solid rgba(139, 139, 139, 0.3);
  border-radius: 24px;
  margin: 3px 0;
  padding: 5px 12px;
  transition: 0.2s;
}
.check-label:hover {
  color: purple;
  background-color: rgb(246, 240, 255);
}
.check-label::before {
  content: "+";
  font-weight: 900;
  padding: 2px 6px 2px 2px;
  transition: transform 0.3s ease-in-out;
}
/* .check-label:focus {
  border: 2px solid #e9a1ff;
} */
input[type="checkbox"]:checked + label {
  color: rgb(85, 0, 71);
  background-color: rgba(228, 232, 255);
  border: 2px solid rgba(105, 115, 255, 0.61);
}
input[type="checkbox"]:checked + label::before {
  content: "=";
  transform: rotate(-360deg);
  transition: transform 0.3s ease-in-out;
}
/* input[type="checkbox"]:focus + label {
  border: 2px solid #e9a1ff;
} */

/* TAGS */
img.link-favicon {
  width: 14px;
  margin-left: 3px;
  position: relative;
  top: 3px;
}
.subject-tag-div {
  margin: 10px;
  display: flex;
  flex-wrap: wrap;
}
.subject-tag {
  background-color: rgb(230, 235, 255);
  width: fit-content;
  padding: 0 10px;
  margin-right: 10px;
  margin-bottom: 8px;
  border-radius: 3px;
  color: black;
  font-family: HK Grotesk;
  font-size: 0.95em;
}
.simple-subject {
  background-color: rgb(230, 235, 255);
  width: fit-content;
  padding: 0 10px;
  margin: auto;
  border-radius: 4px;
  color: black;
  font-family: HK Grotesk;
}

/* Toggle Simple Button */
.toggle-simple {
  visibility: hidden;
  transition: 0.3s linear;
}
.toggle-simple:hover {
  cursor: pointer;
  background-color: aliceblue;
}

/* EXTERNAL CURRICULUM BLOCKS */
/* Mobile */
.external-curriculums-wrapper {
  column-count: 1;
}
.simple-wrapper {
  background: white;
  max-width: 1480px;
  margin: auto;
}
.simple-each {
  border-top: 1px solid;
}
.each-ext-cur-div {
  border: 0.5px solid darkgrey;
  border-radius: 5px;
  border-bottom: 2px solid black;
  padding: 12px 8px 2px 8px;
  background-color: rgb(255, 255, 255);
  display: inline-block;
  width: 95%;
  margin-bottom: 10px;
  text-align: left;
}
.each-ext-cur-div:hover {
  background-color: rgb(247, 255, 254);
  box-shadow: 0px 0px 25px 0px rgba(230, 163, 230, 0.211);
  border-color: rgb(182, 175, 255);
  transition: 0.2s ease-in-out;
  border-bottom-color: mediumpurple;
}
.ext-cur-title {
  font-size: 1.04em;
  margin: 0 0 5px 0;
  padding: 5px 10px;
  color: black;
  font-family: HK Grotesk;
  font-weight: bold;
  overflow-wrap: break-word;
}
.ext-cur-summary p,
.simple-summary p {
  margin: 0.5em;
  color: black;
  margin-left: 10px;
  font-size: 0.8em;
  overflow-wrap: break-word;
}
.simple-summary {
  display: flex;
}
/* Tablet */
@media only screen and (min-width: 560px) {
  .external-curriculums-wrapper {
    column-count: 2;
    column-gap: 10px;
    padding: 0 20px;
  }
  .simple-each {
    display: grid;
    grid-template-columns: 3fr 3fr 1fr;
    align-items: center;
    justify-items: start;
    text-align: left;
  }
  .simple-subject {
    padding: 0 10px;
    background-color: white;
    width: fit-content;
    margin: 0;
    border-radius: 0px;
    color: black;
  }
}
/* Desktop */
@media only screen and (min-width: 840px) {
  .external-curriculums-wrapper {
    column-count: 3;
    max-width: 1080px;
    margin: auto;
  }
  /* Toggle Simple Button */
  .toggle-simple {
    visibility: visible;
    background: white;
    padding: 10px 20px;
    font-size: medium;
    font-family: system-ui;
    border: none;
  }
}
/* Large Desktop */
@media only screen and (min-width: 1260px) {
  .external-curriculums-wrapper {
    column-count: 4;
    max-width: 1440px;
  }
}
