/* FORMS in /add and /create */
.form-div {
  margin: 20px auto 40px auto;
}
#form-message {
  display: block;
  padding: 10px 20px;
  opacity: 0.8;
  width: max-content;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  top: 20%;
  left: 0;
  right: 0;
  text-align: center;
  font-family: HK Grotesk;
  font-size: 1.5em;
  font-weight: bold;
  z-index: 2;
}
.msg-error {
  background-color: rgb(255, 50, 50);
  color: white;
}
.msg-successful {
  background-color: rgba(80, 255, 120, 0.9);
  color: rgb(38, 0, 66);
}
.form-small-input-div {
  display: flex;
  flex-direction: column;
}
.add-form {
  background-color: white;
  margin: 10px;
  padding: 30px 20px 20px 10px;
  border-radius: 5px;
  box-shadow: 0 3px 3px 0 rgb(188 0 226 / 25%);
}
label.form-label {
  padding: 10px;
}
.form-input {
  padding: 7px;
  width: 100%;
  border: none;
  border-bottom: 2px solid rgb(150, 150, 150);
  transition: 0.2s linear;
  font-size: medium;
}
.form-select {
  width: fit-content;
  margin-left: 10px;
}
.form-input:focus-visible {
  background-color: rgb(255, 255, 245);
  border-bottom: 2px solid rgb(89, 0, 255, 0.7);
  outline: none;
  border-radius: 5px;
  transition: 0.2s ease-in-out;
}
.form-submit-btn-div {
  border-left: 3px solid #982095;
  width: fit-content;
  margin: 10px auto 5px;
  padding-right: 10px;
  transition: 0.3s linear;
}
.form-submit-btn {
  font-family: HK Grotesk;
  font-size: 1.3em;
  font-weight: bold;
  padding: 5px 10px;
  border: none;
  background: transparent;
}
.form-submit-btn-div:hover {
  background: #ffccdb;
}
.arrow-icon {
  width: 15px;
  position: relative;
  top: 2px;
}

#add-form-tooltip {
  height: 20px;
  border-left: 2px solid purple;
  margin: 0 10px 25px 10px;
  font-size: 0.8em;
  transition: 0.5s linear;
  color: purple;
  padding: 0 5px;
}
#add-footer {
  text-align: left;
  margin-left: 15px;
  margin-bottom: 15px;
  font-family: HK Grotesk;
}
.disappear {
  display: none;
}
.thank-form {
  margin: 10px;
  background-color: white;
  padding: 10px 15px 10px 10px;
  border-radius: 5px;
  box-shadow: 0 3px 3px 0 rgb(188 0 226 / 25%);
}
#thank-columns {
  margin: -15px 0 0;
  padding: 0 10px;
}
.outline-btn {
  padding: 6px 12px;
  font-family: HK Grotesk;
  font-size: 1.35em;
  font-weight: bold;
  border: 0.7px solid #982095;
  border-radius: 8px;
  background-color: white;
  transition: 0.3s linear;
}
.outline-btn:hover {
  cursor: pointer;
  background-color: lightpink;
}
.thanks-text {
  font-size: 0.85em;
  color: var(--theme);
  padding: 0 5px;
  text-align: left;
}
/* Tablet */
@media only screen and (min-width: 480px) {
  .add-form {
    padding: 30px 60px 20px 40px;
    max-width: 450px;
    margin: auto;
  }
  .form-input {
    margin-top: -9px;
  }
  .form-select {
    max-width: 100%;
  }
  .thank-form {
    padding: 20px 60px 20px 40px;
    max-width: 650px;
    margin: 1px auto;
  }
  #thank-columns {
    margin: 0;
    padding: 0;
  }
  #add-footer {
    margin-left: 50px;
  }
}
