.arrow-btn {
  padding: 10px;
  text-align: left;
  background-color: transparent;
  border: none;
  font-size: 1.4em;
  font-family: HK Grotesk;
  font-weight: bold;
  cursor: pointer;
}
.arrow-btn:hover {
  color: purple;
}
@media only screen and (min-width: 480px) {
  .arrow-btn::before {
    content: "";
    margin: auto;
    margin-right: -15px;
    height: 20px;
    margin-top: -30px;
    top: 35px;
    display: block;
    position: relative;
    z-index: -1;
    background-color: #E3DBFF;
  }
}

/* MISC */
.cta-link {
  display: block;
  background: rgba(118, 0, 246, 0.453);
  color: white;
  font-size: 1.2em;
  font-family: HK Grotesk;
  width: fit-content;
  margin: 40px auto 30px auto;
  padding: 5px 15px;
  transition: 0.7s ease-in-out;
  border-top-right-radius: 18px;
  border: none;
}
.cta-link:hover {
  background: rgb(149, 0, 255);
  color: pink;
}
