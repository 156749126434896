:root {
  --theme: #850595;
  --theme-dark: #690477;
  --bg-grad: white;
  /* --bg-grad: radial-gradient(
    circle,
    rgb(248, 255, 255) 0%,
    rgb(253, 250, 255) 100%
  ); */
}
@font-face {
  font-family: HK Grotesk;
  src: url("https://cdn.glitch.me/605e2a51-d45f-4d87-a285-9410ad350515%2FHKGrotesk-Regular.otf?v=1603136326027")
    format("opentype");
}
@font-face {
  font-family: HK Grotesk;
  font-weight: bold;
  src: url("https://cdn.glitch.me/605e2a51-d45f-4d87-a285-9410ad350515%2FHKGrotesk-Bold.otf?v=1603136323437")
    format("opentype");
}
/* GENERAL STYLES */
body {
  font-family: sans-serif;
  text-align: center;
  background: var(--bg-grad);
}
p {
  line-height: 1.374em;
}
h1,
h2,
h3 {
  color: black;
  font-family: HK Grotesk;
}
ol > li {
  padding: 3px 0;
}
.heading-tr {
  background-color: rgb(50, 50, 50);
  color: white;
  font-weight: bold;
}
.heading-tr > td {
  padding: 12px 7px;
}
tr {
  display: grid;
  grid-template-columns: 1fr 3fr;
}
td {
  border: 1px solid lightgrey;
  padding: 7px;
}
td:first-child {
  font-weight: bold;
}
.wrapper {
  margin: auto;
  padding-right: 5px;
  max-width: 1200px;
  min-height: 100vh;
}
.theme-h2 {
  font-weight: bold;
  font-size: 2em;
  margin-top: 2em;
}
.theme-h2::before {
  content: "";
  margin: auto;
  height: 20px;
  max-width: 10em;
  margin-top: -44px;
  top: 44px;
  display: block;
  position: relative;
  z-index: -1;
}
.theme-h2-short::before {
  content: "";
  margin: auto;
  height: 20px;
  max-width: 5em;
  margin-top: -44px;
  top: 44px;
  display: block;
  position: relative;
  z-index: -1;
}
.theme-h2#explore-h2::before {
  background-color: rgba(251, 253, 0, 0.4);
}
#about-h2::before {
  background-color: rgb(228, 183, 255);
}
.theme-h2#add-h2::before {
  background-color: #ffccdb;
}
.theme-h2#all-h2::before {
  background-color: rgba(226, 226, 226, 1);
}
#info-h2::before {
  background-color: rgba(209, 225, 255, 1);
}
.theme-h2#curriculuminfo-h2::before {
  background-color: rgb(246, 191, 255);
}
.theme-h3 {
  font-size: 1.47em;
  color: var(--theme);
}
.theme-btn {
  margin: 15px 10px 15px 10px;
  padding: 15px;
  font-size: 1.14em;
  background-color: white;
  border: 1px solid var(--theme-dark);
  border-radius: 5px;
  transition: 0.24s;
}
.theme-btn:hover {
  background-color: rgb(255, 230, 242);
  cursor: pointer;
}
.centered-p {
  font-family: HK Grotesk;
  max-width: 680px;
  text-align: left;
  padding: 15px 35px;
  margin: 10px auto;
  background-color: rgb(248, 248, 248);
  border-radius: 2px;
  border-left: 2px solid #d0b2ff;
  border-right: 2px solid #d0b2ff;
}
.p-h3 {
  font-size: 1.47em;
  padding-top: 10px;
  margin-bottom: -20px;
}
/* Tablet */
@media only screen and (min-width: 840px) {
  .p-h3 {
    font-size: 1.87em;
  }
  .centered-p {
    max-width: 800px;
  }
  .theme-h2 {
    font-size: 3em;
    margin: 75px auto 54px auto;
  }
  .theme-h2::before {
    height: 40px;
    margin-top: -40px;
    top: 74px;
  }
}
/* Desktop */
@media only screen and (min-width: 1240px) {
}

/* LINK STYLES (GENERAL) */
::selection {
  background-color: var(--theme);
  color: white;
}
.link,
a {
  text-decoration: none;
  color: rgb(53, 53, 216);
  cursor: pointer;
}
.link:hover,
a:hover {
  color: rgb(73, 17, 126);
}
.link:active,
a:active {
  color: rgba(255, 0, 34, 0.842);
}
.nav-link {
  font-size: 1em;
  font-family: HK Grotesk;
  font-weight: normal;
  color: black;
}

/* LOADING */
.loader {
  border: 10px solid rgb(255, 182, 216);
  border-top: 10px solid var(--theme);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s cubic-bezier(0.175, 0.82, 0.565, 1) infinite;
  margin: auto;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* TEXT BLOCKS */
.warning {
  background-color: rgb(255, 240, 230, 0.9);
  max-width: max-content;
  padding: 10px 20px;
  margin-top: -4px;
  margin-bottom: 30px;
  box-shadow: 0 0.2em 1em 0px rgb(214 170 226 / 70%);
}
.warning-icon {
  width: 20px;
  padding-right: 20px;
  position: relative;
  top: 4px;
}

/* COLUMNS */
.two-columns {
  margin: 20px 0 20px 0;
  text-align: left;
}
.two-columns-2nd {
  margin: 20px 0;
}
.three-columns {
  margin: 20px 0 20px 0;
}
@media only screen and (min-width: 540px) {
  .two-columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 15px;
    margin: 70px 40px 100px 40px;
  }
  .two-columns-2nd {
    margin: 0;
  }
  .three-columns {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 15px;
    margin: 70px 40px 90px 40px;
    text-align: left;
  }
}
.marauto {
  margin: auto;
}
