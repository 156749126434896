.accordion {
  border: none;
  background-color: transparent;
  color: black;
  cursor: pointer;
  padding: 0 10px;
  width: 100%;
  text-align: left;
  font-size: 1.3em;
  font-family: HK Grotesk;
  transition: 0.4s;
  font-weight: bold;
}
.active,
.accordion:hover {
  /* background-color: #e3ffe5; */
}
.accordion:after {
  content: "\002B";
  font-weight: bold;
  float: right;
  width: 20px;
  height: auto;
  margin-left: 0px;
}
.active:after {
  content: "\2212";
}
.panel {
  padding: 0 15px 0 10px;
  text-align: left;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.15s ease-out;
}
.panel-active {
  border-bottom: 2px solid rgb(155, 105, 205);
}
@media only screen and (min-width: 480px) {
  .accordion::before {
    content: "";
    margin: auto;
    margin-right: -15px;
    height: 20px;
    margin-top: -30px;
    top: 35px;
    display: block;
    position: relative;
    z-index: -1;
    background-color: paleturquoise;
    opacity: 0.5;
  }
}
