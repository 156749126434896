#external-examples-div {
  margin: auto;
}
/* Tablet */
@media only screen and (min-width: 480px) {
  #external-examples-div {
    display: grid;
    max-width: 620px;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 15px;
  }
}
